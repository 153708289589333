import { useMutation } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';
import { FormData } from '@/core/models/User';

export default function useSignupMutation() {
  const { authGateway } = useGateways();
  return useMutation({
    mutationFn: (data: FormData) => authGateway.signUp(data),
  });
}
