import axios, { AxiosResponse } from 'axios';

import { PublicUser } from '@/core/models/User';
import { LoginPayload } from '@/pages/auth/login/types';
import { AuthGateway } from '@/pages/auth/ports/auth';
import { camelToSnakeCase, snakeToCamelCase } from '@/utils/caseTransformer';

// gateway only for /auth API endpoint
export function authGateway(): AuthGateway {
  function login(payload: LoginPayload): Promise<PublicUser> {
    return axios
      .post('/login', camelToSnakeCase(payload), {
        baseURL: `${import.meta.env.VITE_AUTH_BASE_URL}`,
      })
      .then(({ data }: AxiosResponse) => snakeToCamelCase(data));
  }

  return { login };
}
