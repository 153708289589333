import { Options, PascalCaseOptions } from 'change-case';
import { camelCase as camelCaseFn, snakeCase as snakeCaseFn } from 'change-case/keys';
import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest';

type ConvertibleType = unknown | ConvertibleType[] | { [key: string]: ConvertibleType };

export const snakeToCamelCase = <T extends ConvertibleType>(
  obj: T,
  options?: PascalCaseOptions,
): CamelCasedPropertiesDeep<T> =>
  camelCaseFn(obj, -1, options) as unknown as CamelCasedPropertiesDeep<T>;

export const camelToSnakeCase = <T extends ConvertibleType>(
  obj: T,
  options?: Options,
): SnakeCasedPropertiesDeep<T> =>
  snakeCaseFn(obj, -1, options) as unknown as SnakeCasedPropertiesDeep<T>;
