import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toastError } from '@/components/Toastify';
import { useAuthGateways } from '@/pages/auth/contexts/AuthGatewayProviders';
import { LoginError, LoginErrorData, LoginPayload } from '@/pages/auth/login/types';
import { snakeToCamelCase } from '@/utils/caseTransformer';

interface LoginMutationProps {
  handleError: (data: LoginError) => void;
}

function parse({ error }: LoginErrorData): LoginError {
  return snakeToCamelCase(error);
}

export default function useLoginMutation({ handleError }: LoginMutationProps) {
  const { authGateway } = useAuthGateways();

  return useMutation({
    mutationFn: (payload: LoginPayload) => authGateway.login(payload),
    onError: (error: AxiosError<LoginErrorData>) => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          handleError(parse(error.response.data));
        } else if (error.response.status === 404) {
          toastError(error.message);
        } else {
          toastError();
        }
      }
    },
  });
}
