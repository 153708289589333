import MuiSelect, { BaseSelectProps } from '@mui/material/Select';

import { WithLabel } from 'easyship-components';

interface SelectProps extends BaseSelectProps {
  label: string;
  placeholder?: string;
  helperText?: string;
  error: boolean;
}

export default function Select({
  label,
  children,
  placeholder,
  helperText,
  error,
  ...props
}: SelectProps) {
  return (
    <WithLabel value={label} className="relative">
      <>
        <MuiSelect error={error} {...props} size="small">
          {children}
        </MuiSelect>
        {helperText && (
          <p className={'text-base' + (error ? ' text-red-900' : ' text-ink-300')}>{helperText}</p>
        )}
      </>
    </WithLabel>
  );
}
