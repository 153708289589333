import axios from 'axios';

import { Address } from '@/core/models/Address';
import { StripeParams } from '@/core/models/Payment';
import { User } from '@/core/models/User';
import ValidationGateway from '@/ports/validation';

import { mapAddressToApi, mapPaymentToApi, mapUserToApi } from './mappers/formDataMapper';

export default function createApiValidationGateway(): ValidationGateway {
  const _urlParams = new URLSearchParams(window.location.search);
  const _clientId = _urlParams.get('client_id');

  async function validateUser(data: User): Promise<number> {
    const { status } = await axios.post(
      `/oauth2/applications/${_clientId}/steps/${data.id}/validate`,
      { step: { form_elements: mapUserToApi(data) } },
    );
    return status;
  }

  async function validateAddress(data: Address): Promise<number> {
    const { status } = await axios.post(
      `/oauth2/applications/${_clientId}/steps/${data.id}/validate`,
      { step: { form_elements: mapAddressToApi(data) } },
    );
    return status;
  }

  async function validatePayment({ stripe, data }: StripeParams): Promise<string> {
    const {
      status,
      data: { client_secret, stripe_token },
    } = await axios.post(`/oauth2/applications/${_clientId}/steps/${data.id}/validate`, {
      step: { form_elements: mapPaymentToApi(data) },
    });

    // Extra validation with 3DS needed if status is 202
    if (status === 202) {
      await stripe.handleNextAction({ clientSecret: client_secret });
    }
    return stripe_token;
  }

  return {
    validateUser,
    validateAddress,
    validatePayment,
  };
}
