import { Input } from 'easyship-components';
import React from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from 'react-hook-form';

export interface ControlledInputProps<T extends FieldValues> {
  label?: React.ReactNode;
  disabled?: boolean;
  helperText?: string;
  control: Control<T>;
  rules?: Pick<RegisterOptions<FieldValues>, 'required' | 'pattern' | 'minLength'>;
  name: FieldPath<T>;
  maxLength?: number;
  type?: string;
  onEnter?: () => void;
}

export const ControlledInput = <T extends FieldValues>({
  disabled,
  helperText,
  control,
  rules,
  name,
  maxLength,
  onEnter,
  ...props
}: ControlledInputProps<T>) => {
  return (
    // TODO: accept status warning
    <Controller
      control={control}
      rules={rules}
      defaultValue={'' as PathValue<T, Path<T>>}
      render={({ field: { ref, onChange, value }, fieldState }) => (
        <Input
          onChange={onChange}
          value={value}
          ref={ref}
          disabled={disabled}
          {...props}
          status={fieldState.error ? 'error' : 'default'}
          statusText={fieldState.error?.message || helperText}
          maxLength={maxLength}
        />
      )}
      name={name}
    />
  );
};
