interface FooterProps {
  step: string;
  platform?: string;
}

export const Footer = ({ step, platform }: FooterProps) => {
  return (
    <div className="mt-5">
      {step === 'terms' ? (
        <p className="text-base text-ink-300">
          By continuing, Easyship will receive ongoing access to your ${platform} account
          information and user information.
        </p>
      ) : (
        <p className="text-base text-ink-300">
          By creating an account, you agree to the Easyship{' '}
          <a
            className="text-blue-500 hover:text-blue-700"
            href="https://www.easyship.com/legal/terms/overview"
            target="_blank"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            className="text-blue-500 hover:text-blue-700"
            href="https://www.easyship.com/legal/privacy"
            target="_blank"
          >
            Privacy & Data Protection Policy
          </a>
        </p>
      )}
    </div>
  );
};
