import { useQuery } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';

export default function useCountries() {
  const { countriesGateway } = useGateways();

  return useQuery({
    queryKey: ['countries'],
    queryFn: () => countriesGateway.getCountries(),
    staleTime: Infinity,
  });
}
