import { Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { ControlledInput } from '@/components/ControlledInput';
import { toastError } from '@/components/Toastify';
import { SIGNUP_LOGIN_EMAIL_REGEX } from '@/core/regex';
import useRecaptcha from '@/hooks/useRecaptcha';
import useLoginMutation from '@/pages/auth/login/hooks/useLoginMutation';
import { LoginError, LoginErrorCode, LoginFormData, LoginPayload } from '@/pages/auth/login/types';

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME === 'development';

export const LoginForm = () => {
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const { control, handleSubmit } = useForm<LoginFormData>();
  const navigate = useNavigate();
  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  const handleError = (data: LoginError) => {
    if (data.code === LoginErrorCode.MFA_REQUIRED) {
      navigate('/auth/authenticate');
    } else if (data.code === LoginErrorCode.INVALID_CREDENTIALS) {
      toastError(data.message);
    } else if (data.code === LoginErrorCode.LOCKED_ACCOUNT) {
      navigate('/auth/locked');
    }
    fetchAndSetRecaptcha();
  };

  const { mutateAsync: loginMutateAsync, isPending: isLoading } = useLoginMutation({ handleError });

  const onSubmit: SubmitHandler<LoginFormData> = ({ email, password }: LoginFormData) => {
    const payload: LoginPayload = {
      email: email.trim(),
      password,
      recaptchaToken,
    };

    loginMutateAsync(payload, {
      onSuccess: (data) => {
        if (Cookies.get(credentialsEnv)) removeCredentials();
        Cookies.set(credentialsEnv, data.session.token, {
          domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
        setTimeout(() => {
          window.location.replace(
            `https://app${IS_DEVELOPMENT ? '-staging' : ''}.easyship.com/dashboard`,
          );
        }, 500);
      },
    });
  };

  function removeCredentials(): void {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
    Cookies.remove(credentialsEnv, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <ControlledInput
        label="Email"
        maxLength={50}
        control={control}
        name="email"
        rules={{
          required: { value: true, message: 'This field is required.' },
          pattern: { value: SIGNUP_LOGIN_EMAIL_REGEX, message: 'Please enter a valid email.' },
        }}
      />
      <ControlledInput
        label="Password"
        type="password"
        maxLength={50}
        control={control}
        name="password"
        rules={{
          required: { value: true, message: 'This field is required.' },
        }}
      />
      <div>
        <Link className="text-blue-500" to="/auth/reset-password">
          Forgot your password?
        </Link>
      </div>
      <Button
        onClick={handleSubmit(onSubmit)}
        color="primary"
        className="w-full"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Login
      </Button>
      <p>
        No account yet?{' '}
        <a
          className="text-blue-500 hover:text-blue-700"
          href={`https://app${IS_DEVELOPMENT ? '-staging' : ''}.easyship.com/signup`}
          rel="noreferrer"
        >
          Get started for free
        </a>
      </p>
    </form>
  );
};
