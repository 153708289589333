import { loadStripe, Stripe } from '@stripe/stripe-js';
import axios from 'axios';

import PaymentGateway from '@/ports/payment';

export default function createApiPaymentGateway(): PaymentGateway {
  async function getStripeKey(countryId: number): Promise<string> {
    const {
      data: {
        stripe: { public_key },
      },
    } = await axios.get(`oauth2/stripe?country_id=${countryId}`);
    return public_key;
  }

  async function load(countryId: number): Promise<Stripe | null> {
    const stripeKey = await getStripeKey(countryId);
    const stripe = await loadStripe(stripeKey);
    return stripe;
  }

  return { load };
}
