import { AuthGatewayProvider } from '@/pages/auth/contexts/AuthGatewayProviders';
import { LoginForm } from '@/pages/auth/login/components/LoginForm';

export const LoginPage = () => {
  return (
    <>
      <p className="mt-2 font-sans text-lg font-light text-ink-500">
        Login to your Easyship account
      </p>
      <div className="w-2/4 mt-5 lg:w-2/6">
        <AuthGatewayProvider>
          <LoginForm />
        </AuthGatewayProvider>
      </div>
    </>
  );
};
