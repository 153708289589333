import { useQuery } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';

export default function useFetchSteps() {
  const { authGateway } = useGateways();

  return useQuery({
    queryKey: ['steps'],
    queryFn: () => authGateway.fetchSteps(),
  });
}
