import { Exclaim } from 'easyship-components/icons';

export const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-sky-300 text-ink-700">
      <div className="text-[72px]">
        <Exclaim className="w-24 h-24 mb-6 text-sky-700" />
      </div>
      <h4 className="m-2 text-2xl font-bold">We encountered an error.</h4>
      <p>
        Please retry or contact{' '}
        <a
          className="text-blue-500"
          href="https://support.easyship.com/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer"
        >
          Easyship
        </a>{' '}
        for assistance if the issue persists.
      </p>
    </div>
  );
};
