import axios from 'axios';

import { Country, CountryResponse } from '@/core/models/Address';
import CountriesGateway from '@/ports/countries';

async function getCountries(): Promise<Country[]> {
  const { data } = await axios.get('oauth2/countries');
  const countries = data.countries.map((country: CountryResponse) => {
    return {
      id: country.id,
      alpha2: country.alpha2,
      name: country.simplified_name,
      postalCodeRegex: country.postal_code.regex,
      requiresState: country.requires_state,
    };
  });

  return countries.sort((a: Country, b: Country) => (a.name > b.name ? 1 : -1));
}

export default function createApiCountriesGateway(): CountriesGateway {
  return { getCountries };
}
