import { useQuery } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';

export default function useLoadStripe(countryId: number) {
  const { paymentGateway } = useGateways();

  return useQuery({
    queryKey: ['loadStripe'],
    queryFn: () => paymentGateway.load(countryId),
    staleTime: Infinity,
  });
}
