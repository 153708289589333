import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';

import COLORS from '@/colors';
import { toastError } from '@/components/Toastify';
import { Button, Input } from 'easyship-components';

interface StripeCardComponentProps {
  handleToken: (token: string) => void;
}

const stripeStyle = {
  base: {
    iconColor: COLORS.inkFaded,
    color: COLORS.inkDark,
    fontWeight: '500',
    fontFamily: 'Karla, sans-serif',
    fontSize: '13px',
    fontSmoothing: 'antialiased',

    ':-webkit-autofill': {
      color: '#fce883',
      backgroundColor: 'transparent',
    },
    '::placeholder': {
      color: COLORS.inkLight,
    },
  },
  invalid: {
    iconColor: COLORS.redNormal,
    color: COLORS.redNormal,
  },
};

export const StripeCardComponent = ({ handleToken }: StripeCardComponentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const createToken = async () => {
    const card = elements && elements.getElement(CardElement);
    if (card && stripe) {
      try {
        const response = await stripe.createToken(card, { name: cardholderName });
        return response.token?.id;
      } catch (err) {
        toastError();
      }
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    const token = await createToken();
    token && handleToken(token);
    setIsLoading(false);
  };

  return (
    <>
      <div className="p-3 bg-white border rounded-sm border-sky-500">
        <CardElement options={{ style: stripeStyle }} />
      </div>
      <Input placeholder="Name on card" onChange={(e) => setCardholderName(e.target.value)} />
      <Button loading={isLoading} onClick={handleClick} color="primary" className="w-full">
        Continue
      </Button>
    </>
  );
};
