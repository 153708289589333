import countryList from 'flag-icons/country.json';

interface CountryFlagProps {
  alpha2?: string;
}

interface CountryProperty {
  code: string;
  flag_1x1: string;
  flag_4x3: string;
  iso: boolean;
  name: string;
}

export default function CountryFlag({ alpha2 }: CountryFlagProps) {
  const lowerCaseAlpha2 = alpha2?.toLowerCase();
  const isFlagAvailable =
    alpha2 && countryList.some((country: CountryProperty) => country.code === lowerCaseAlpha2);
  const flagClassName = isFlagAvailable ? `fi-${alpha2.toLowerCase()} shadow-xs` : 'fi-xx';

  return <span className={`fi ${flagClassName} h-4 w-5 rounded-sm mr-2`} />;
}
