import React, { createContext, useContext } from 'react';

import { authGateway } from '@/pages/auth/gateways/authGateway';
import { AuthGateway } from '@/pages/auth/ports/auth';

export interface AuthGatewayContextData {
  authGateway: AuthGateway;
}

const AuthGatewayContext = createContext<AuthGatewayContextData | undefined>(undefined);

interface AuthGatewayProviderProps {
  children: React.ReactNode;
}

function AuthGatewayProvider({ children }: AuthGatewayProviderProps) {
  const data: AuthGatewayContextData = {
    authGateway: authGateway(),
  };

  return <AuthGatewayContext.Provider value={data}>{children}</AuthGatewayContext.Provider>;
}

function useAuthGateways() {
  const gatewayContext = useContext(AuthGatewayContext);

  if (gatewayContext === undefined) {
    throw new Error('useAuthGateways must be used within a AuthGatewayProvider');
  }
  return gatewayContext;
}

export { AuthGatewayProvider, useAuthGateways };
