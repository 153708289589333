import { createTheme } from '@mui/material/styles';
import COLORS from './colors';

const SHADOW_SM = '0 2px 4px rgba(40, 78, 102, 0.2)';
const SHADOW_LG = '0 4px 4px 0 rgba(40, 78, 102, 0.2)';

export const theme = createTheme({
  typography: {
    fontFamily: "'Karla', sans-serif",
    fontSize: 13,
    h1: {
      fontSize: 20,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      color: COLORS.inkDarkest,
    },
    h3: {
      fontSize: 16,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      color: COLORS.inkDarkest,
    },
    h5: {
      fontSize: 13,
      color: COLORS.inkNormal,
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      color: COLORS.inkNormal,
    },
    body2: {
      fontSize: 13,
      color: COLORS.inkDark,
    },
    subtitle1: {
      fontSize: 16,
      color: COLORS.inkNormal,
    },
    subtitle2: {
      fontSize: 11,
      color: COLORS.inkNormal,
    },
    caption: {
      fontSize: 13,
      color: COLORS.inkLight,
      fontStyle: 'italic',
    },
  },
  palette: {
    background: { default: COLORS.skyNormal },
    success: {
      main: COLORS.success,
    },
    error: {
      main: COLORS.failure,
    },
    teal: {
      main: COLORS.tealNormal,
      dark: COLORS.tealDark,
      light: COLORS.tealLight,
      contrastText: COLORS.white,
    },
    blue: {
      main: COLORS.blueNormal,
      dark: COLORS.blueDark,
      light: COLORS.blueLight,
    },
    green: {
      main: COLORS.greenNormal,
      dark: COLORS.greenDark,
      light: COLORS.greenLight,
    },
    yellow: {
      main: COLORS.yellowNormal,
      dark: COLORS.yellowDark,
      light: COLORS.yellowLight,
    },
    red: {
      main: COLORS.redNormal,
      dark: COLORS.redDark,
      light: COLORS.redLight,
    },
    ink: {
      main: COLORS.inkNormal,
      dark: COLORS.inkDark,
      light: COLORS.inkLight,
    },
    sky: {
      main: COLORS.skyNormal,
      dark: COLORS.skyDark,
      light: COLORS.skyLight,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          transition: 'none',
        },
        contained: {
          height: 35,
          boxShadow: SHADOW_SM,

          '&.Mui-disabled': {
            backgroundImage: 'none',
            backgroundColor: COLORS.skyDark,
          },

          '&:hover': {
            backgroundImage: 'none',
            boxShadow: SHADOW_SM,
          },

          '&:active': {
            backgroundImage: 'none !important',
            boxShadow: SHADOW_LG,
          },
        },
        containedPrimary: {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
          color: COLORS.white,

          '&:hover': { backgroundColor: COLORS.greenNormal },
          '&:active': { backgroundColor: COLORS.greenDark },
        },
        containedSecondary: {
          backgroundColor: '#E3E6EA',
          color: COLORS.inkNormal,

          '&:hover': { backgroundColor: COLORS.white },
          '&:active': { backgroundColor: COLORS.skyDark },
        },
        containedInfo: {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.blueNormal} 0%, ${COLORS.blueDark} 100%)`,
          color: COLORS.white,

          '&:hover': { backgroundColor: COLORS.blueNormal },
          '&:active': { backgroundColor: COLORS.blueDark },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: COLORS.white,
          width: '100%',
          padding: 8,
          borderRadius: 50,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          border: 'none',
          '&.Mui-selected, &.Mui-selected:hover, &:hover': { backgroundColor: 'transparent' },
          '&.MuiToggleButton-primary.Mui-selected': {
            borderColor: COLORS.blueNormal,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: COLORS.inkDark,
          borderColor: COLORS.skyDark,
          borderWidth: 1,
          borderStyle: 'solid',
          width: '100%',
          backgroundColor: COLORS.white,

          '&.Mui-focused': {
            borderColor: COLORS.inkLight,
          },

          '&.Mui-disabled': {
            color: COLORS.inkDark,
            backgroundColor: COLORS.skyLight,
          },

          '&.Mui-error': {
            borderColor: COLORS.redDark,
            backgroundColor: COLORS.redLight,
          },
        },
        notchedOutline: {
          borderWidth: '0 !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: COLORS.inkNormal,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 20,
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
  },
});
