import React, { createContext, useContext } from 'react';

import createApiAuthGateway from '@/gateways/api/apiAuthGateway';
import createApiCountriesGateway from '@/gateways/api/apiCountriesGateway';
import createApiPaymentGateway from '@/gateways/api/apiPaymentGateway';
import createApiValidationGateway from '@/gateways/api/apiValidationGateway';
import AuthGateway from '@/ports/auth';
import CountriesGateway from '@/ports/countries';
import PaymentGateway from '@/ports/payment';
import ValidationGateway from '@/ports/validation';

export interface GatewayContextData {
  authGateway: AuthGateway;
  countriesGateway: CountriesGateway;
  paymentGateway: PaymentGateway;
  validationGateway: ValidationGateway;
}

const GatewayContext = createContext<GatewayContextData | undefined>(undefined);

interface GatewayProviderProps {
  children: React.ReactNode;
}

function GatewayProvider({ children }: GatewayProviderProps) {
  const data: GatewayContextData = {
    authGateway: createApiAuthGateway(),
    countriesGateway: createApiCountriesGateway(),
    paymentGateway: createApiPaymentGateway(),
    validationGateway: createApiValidationGateway(),
  };

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>;
}

function useGateways() {
  const gatewayContext = useContext(GatewayContext);

  if (gatewayContext === undefined) {
    throw new Error('useGateways must be used within a GatewayProvider');
  }
  return gatewayContext;
}

export { GatewayProvider, useGateways };
