import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ToggleData {
  id: string;
  value: string;
}

interface ToggleProps {
  handleChange: () => void;
  selected: string;
  selection: ToggleData[];
}

export const Toggle = ({ handleChange, selected, selection }: ToggleProps) => {
  return (
    <ToggleButtonGroup
      orientation="horizontal"
      onChange={handleChange}
      className="w-full p-2 bg-white rounded-full"
      value={selected}
    >
      <div className="relative w-full">
        <div
          className={`w-1/2 h-9 absolute ${selected === selection[0].id ? 'left-0' : 'left-1/2'} bg-no-repeat bg-origin-padding bg-teal-gradient rounded-full transition-all duration-500 ease-in-out`}
        ></div>
        {selection.map((item) => (
          <ToggleButton
            disableRipple
            value={item.id}
            className="w-1/2 h-9 rounded-full [&.Mui-selected]:bg-transparent border-none hover:bg-transparent [&.Mui-selected]:hover:bg-transparent"
            key={item.id}
          >
            <h3 className="text-lg normal-case text-ink-900">{item.value}</h3>
          </ToggleButton>
        ))}
      </div>
    </ToggleButtonGroup>
  );
};
