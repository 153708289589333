import { Outlet } from 'react-router-dom';

export const AuthPage = () => {
  return (
    <div className="flex flex-col items-center h-[100dvh] p-12 bg-sky-300">
      <img src="/images/easyship.svg" alt="easyship-logo" className="h-[65px] mb-2" />
      <Outlet />
    </div>
  );
};
