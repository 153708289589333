import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';
import { LoginParams } from '@/core/models/User';
import { AxiosError } from 'axios';

export default function useLoginMutation() {
  const { authGateway } = useGateways();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ user, token }: LoginParams) => authGateway.login({ user, token }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['steps'] });
    },
    onError: (error: AxiosError<any>) => error,
  });
}
