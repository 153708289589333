import { BaseSyntheticEvent, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ChevronBackward } from 'easyship-components/icons';

// Components
import { Footer } from '@/components/Footer';
import Loading from '@/components/Loading';
import { FormData } from '@/core/models/User';
import { Button } from 'easyship-components';
// Hooks
import useCountries from '@/hooks/useCountries';
import useFetchSteps from '@/hooks/useFetchSteps';
import useFetchUserData from '@/hooks/useFetchUserData';
import useRecaptcha from '@/hooks/useRecaptcha';
import useSignupMutation from '@/hooks/useSignupMutation';
// Pages
import { AddressPage } from '@/pages/AddressPage/AddressPage';
import { ConfirmationPage } from '@/pages/ConfirmationPage/ConfirmationPage';
import { PaymentPage } from '@/pages/PaymentPage/PaymentPage';
import { UserPage } from '@/pages/UserPage/UserPage';

import { toastError, toastSuccess } from './components/Toastify';

function App() {
  const { data: userData, isLoading: isLoadingUserData } = useFetchUserData();
  const { data: steps, isLoading: isLoadingSteps } = useFetchSteps();
  const { data: countries, isLoading: isLoadingCountries } = useCountries();
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const { mutateAsync: signupMutateAsync } = useSignupMutation();
  const methods = useForm<FormData>();
  methods.register('user.id');
  methods.register('address.id');
  methods.register('payment.id');

  const [isLoading, setIsLoading] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentStep = steps
    ? steps[currentStepIndex]
      ? steps[currentStepIndex].template
      : 'confirmation'
    : 'error';

  const getStepId = (stepName: string) => {
    return steps?.find((step) => step.template === stepName)?.id || '';
  };

  const updateStep = (type: string = 'next') => {
    setCurrentStepIndex((prevValue) => (type === 'next' ? (prevValue += 1) : (prevValue -= 1)));
  };

  const onSubmit: SubmitHandler<FormData> = async (data, e?: BaseSyntheticEvent) => {
    e?.preventDefault();
    setIsLoading(true);
    const url = await signupMutateAsync(data);
    setIsLoading(false);
    if (url) {
      toastSuccess(
        'Your account has been created. You will be automatically redirected in a few seconds.',
      );
      window.location.href = url;
    } else {
      toastError();
    }
  };

  if (isLoading || isLoadingUserData || isLoadingSteps || isLoadingCountries) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center w-screen h-screen p-5">
      {/* adding padding bottom for small screen so that the footer is not too low */}
      <div className="max-w-[400px] pb-10 md:pb-0">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex items-center justify-center mt-10">
              <img src="/images/easyship.svg" className="h-10" />
              {currentStep === 'confirmation' && userData?.platform.logo && (
                <>
                  <p className="mx-4">x</p>
                  <img src={userData.platform.logo} className="h-10" />
                </>
              )}
            </div>
            {currentStep === 'user' && (
              <UserPage
                onValidated={updateStep}
                stepId={getStepId(currentStep)}
                countries={countries || []}
                recaptchaToken={recaptchaToken}
              />
            )}
            {currentStep === 'address' && (
              <AddressPage
                onValidated={updateStep}
                stepId={getStepId(currentStep)}
                countries={countries || []}
              />
            )}
            {currentStep === 'payment_source' && (
              <PaymentPage
                onValidated={updateStep}
                stepId={getStepId(currentStep)}
                countryId={methods.getValues('address.country')}
              />
            )}
            {currentStep === 'confirmation' && (
              <>
                <ConfirmationPage platformName={userData?.platform.name || ''} />
                {/* TODO: Ask the team about button color, voy doesn't have blue button */}
                <Button
                  type="submit"
                  className="w-full text-white bg-gradient-to-b from-blue-500 to-blue-700 hover:bg-blue-500 hover:bg-none"
                >
                  Authorize
                </Button>
              </>
            )}
            {currentStepIndex > 0 && (
              <div className="mt-2 text-ink-500">
                {/* TODO: change bg color on hover (currently bg of page is sky-300 and hover color is sky-300), so hover color is not showing */}
                <Button
                  type="button"
                  flat
                  className="flex items-center w-full hover:bg-sky-500"
                  onClick={() => updateStep('previous')}
                >
                  <ChevronBackward />
                  Go back
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
        <Footer step={currentStep} />
      </div>
    </div>
  );
}

export default App;
