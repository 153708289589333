import { Address } from '@/core/models/Address';
import { PaymentSource } from '@/core/models/Payment';
import { User } from '@/core/models/User';

export function mapAddressToApi(data: Address) {
  return [
    { id: 'line_1', value: data.addressLine1 },
    { id: 'line_2', value: data.addressLine2 },
    { id: 'city', value: data.city },
    { id: 'state', value: data.state },
    { id: 'postal_code', value: data.postalCode },
    { id: 'country_id', value: data.country },
    { id: 'contact_name', value: data.name },
    { id: 'contact_email', value: data.email },
    { id: 'contact_phone', value: data.phoneNumber },
    { id: 'company_name', value: data.companyName },
  ];
}

export function mapUserToApi(data: User) {
  return [
    { id: 'first_name', value: data.firstName },
    { id: 'last_name', value: data.lastName },
    { id: 'password', value: data.password },
    { id: 'country_id', value: data.country },
    { id: 'email', value: data.email },
    { id: 'company_name', value: data.companyName },
  ];
}

export function mapPaymentToApi(data: PaymentSource) {
  return [
    { id: 'stripe_token', value: data.token },
    { id: 'country_id', value: data.countryId },
  ];
}
