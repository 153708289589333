import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ControlledInput } from '@/components/ControlledInput';
import { countriesWithRequiredState } from '@/core/countryRules';
import { Country } from '@/core/models/Address';
import { FormData } from '@/core/models/User';
import { PHONE_REGEX } from '@/core/regex';
import { Button } from 'easyship-components';
// Hooks
import useValidateAddressMutation from '@/hooks/validation/useValidateAddressMutation';
import { AxiosError } from 'axios';
import { toastError } from '@/components/Toastify';

interface AddressPageProps {
  onValidated: () => void;
  stepId: string;
  countries: Country[];
}

export const AddressPage = ({ onValidated, stepId, countries }: AddressPageProps) => {
  const { mutateAsync: validationMutateAsync } = useValidateAddressMutation();
  const { control, handleSubmit, getValues, setValue } = useFormContext<FormData>();
  setValue('address.id', stepId);
  const [countryId] = useWatch({ control, name: ['address.country'] });

  const [isLoading, setIsLoading] = useState(false);
  const selectedCountry = countries.find((country) => country.id === countryId);
  const postalCodeRegex = new RegExp(selectedCountry?.postalCodeRegex || '');
  const countryAlpha2 = selectedCountry?.alpha2 || '';

  const handleClick = async () => {
    setIsLoading(true);
    const response = await validationMutateAsync(getValues('address'), {
      onError: (err) => {
        if (err.response) {
          err?.response?.data.errors.forEach((error: string) => {
            toastError(error);
          });
        } else {
          toastError();
        }
      },
    });
    if (response === 200) onValidated();
    setIsLoading(false);
  };

  return (
    <>
      <div className="grid grid-cols-2 my-6 gap-x-2 gap-y-3">
        <ControlledInput
          label="Phone Number"
          maxLength={20}
          control={control}
          name="address.phoneNumber"
          onEnter={handleSubmit(handleClick)}
          rules={{
            required: { value: true, message: 'This field is required.' },
            pattern: { value: PHONE_REGEX, message: 'Please enter a valid phone number.' },
          }}
        />
        {!getValues('address.companyName') && (
          <ControlledInput
            label="Company Name"
            maxLength={20}
            control={control}
            name="address.companyName"
            onEnter={handleSubmit(handleClick)}
            rules={{
              required: { value: true, message: 'This field is required.' },
            }}
          />
        )}
        <ControlledInput
          label="Postal Code"
          maxLength={20}
          control={control}
          name="address.postalCode"
          onEnter={handleSubmit(handleClick)}
          rules={{
            required: { value: true, message: 'This field is required.' },
            pattern: { value: postalCodeRegex, message: 'Please enter a valid postal code.' },
          }}
        />
        <ControlledInput
          label="State"
          maxLength={100}
          control={control}
          name="address.state"
          onEnter={handleSubmit(handleClick)}
          rules={{
            required: {
              value: countriesWithRequiredState.includes(countryAlpha2),
              message: 'This field is required.',
            },
          }}
        />
        <ControlledInput
          label="City"
          maxLength={100}
          control={control}
          name="address.city"
          onEnter={handleSubmit(handleClick)}
          rules={{ required: { value: true, message: 'This field is required.' } }}
        />

        <div className="col-span-2">
          <ControlledInput
            label="Address Line 1"
            maxLength={35}
            control={control}
            name="address.addressLine1"
            onEnter={handleSubmit(handleClick)}
            rules={{ required: { value: true, message: 'This field is required.' } }}
          />
        </div>
        <div className="col-span-2">
          {/* TODO: change the optional with updated optional label */}
          <ControlledInput
            label="Address Line 2 (optional)"
            maxLength={35}
            control={control}
            name="address.addressLine2"
            onEnter={handleSubmit(handleClick)}
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit(handleClick)}
        color="primary"
        className="w-full"
        loading={isLoading}
      >
        Continue
      </Button>
    </>
  );
};
