import { useMutation } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';
import { Address } from '@/core/models/Address';
import { AxiosError } from 'axios';

export default function useValidateAddressMutation() {
  const { validationGateway } = useGateways();
  return useMutation({
    mutationFn: (data: Address) => validationGateway.validateAddress(data),
    onError: (error: AxiosError<any>) => error,
  });
}
