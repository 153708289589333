import './toastify.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { Check as CheckIcon, Exclaim as ExclaimIcon } from 'easyship-components/icons';
import {
  cssTransition,
  toast,
  ToastContainer,
  ToastContainerProps,
  ToastOptions,
} from 'react-toastify';

const animation = cssTransition({
  enter: 'easyship-animation-in',
  exit: 'easyship-animation-out',
});

const toasterConfig: ToastContainerProps = {
  position: 'bottom-center',
  autoClose: 0,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  theme: 'light',
  closeButton: false,
  transition: animation,
  style: { zIndex: 999999 },
};

const DEFAULT_TOAST_WITHOUT_ACTION_CONFIG = {
  closeButton: false,
  closeOnClick: true,
  autoClose: 3000,
};

// line-clamp class is added to handle the error that is super long.
export function toastError(error?: string, toastOptions?: ToastOptions) {
  return toast(
    <div className="overflow-auto line-clamp-3">
      {error ? error : 'Something went wrong. Please try again.'}
    </div>,
    {
      icon: () => <ExclaimIcon fontSize="small" />,
      ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
      ...toastOptions,
    },
  );
}

export function toastSuccess(message: string, toastOptions?: ToastOptions) {
  return toast(<div className="overflow-auto line-clamp-3">{message}</div>, {
    icon: () => <CheckIcon fontSize="small" />,
    ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
    ...toastOptions,
  });
}

export function toastInfo(message: string, toastOptions?: ToastOptions) {
  return toast(<div className="overflow-auto line-clamp-3">{message}</div>, {
    icon: () => <ExclaimIcon fontSize="small" />,
    ...DEFAULT_TOAST_WITHOUT_ACTION_CONFIG,
    ...toastOptions,
  });
}

export function Toastify() {
  return <ToastContainer {...toasterConfig} />;
}
