import { useState } from 'react';

import useRecaptcha from '@/hooks/useRecaptcha';
import { ResetPasswordForm } from '@/pages/auth/reset-password/components/ResetPasswordForm';

export const ResetPasswordPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();

  return (
    <>
      <p className="mt-2 font-sans text-lg font-light text-ink-500">Reset your password</p>
      <div className="w-2/4 mt-5 lg:w-2/6">
        {userEmail === '' ? (
          <ResetPasswordForm
            onResetError={fetchAndSetRecaptcha}
            onResetSuccess={setUserEmail}
            recaptchaToken={recaptchaToken}
          />
        ) : (
          <p className="text-center">
            An email has been sent to <b>{userEmail}</b>. Please click the link in that email to
            complete the process.
          </p>
        )}
      </div>
    </>
  );
};
