interface ConfirmationPageProps {
  platformName: string;
}

export const ConfirmationPage = ({ platformName }: ConfirmationPageProps) => {
  return (
    <div className="text-center text-ink-900">
      <div className="grid gap-3 mx-8 my-10">
        <p>Link your Easyship account to {platformName || 'your platform'}.</p>
        <p>
          {platformName || 'Your platform'} will be able to access your data, manage your account
          and payment methods, request shipping rates and purchase labels. You can remove access in
          your Easyship account.
        </p>
      </div>
    </div>
  );
};
