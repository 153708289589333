import { useQuery } from '@tanstack/react-query';

import { useGateways } from '@/contexts/gateways';

export default function useFetchUserData() {
  const { authGateway } = useGateways();

  return useQuery({
    queryKey: ['userData'],
    queryFn: () => authGateway.fetchUserData(),
    staleTime: Infinity,
  });
}
