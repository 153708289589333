import { CamelCasedPropertiesDeep } from 'type-fest';

export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginPayload {
  recaptchaToken: string;
  email: string;
  password: string;
}

// Login Error Data
export enum LoginErrorCode {
  MFA_REQUIRED = 'mfa_required',
  LOCKED_ACCOUNT = 'locked_account',
  INVALID_CREDENTIALS = 'invalid_credentials',
}

interface MfaTokenData {
  token: string;
  default: string;
  available: string[];
  email_otp: {
    expires_at: string;
    resend_allowed_at: string;
  };
}

export type MfaToken = CamelCasedPropertiesDeep<MfaTokenData>;
export interface LoginErrorData {
  error: {
    code: LoginErrorCode;
    message: string;
    mfa?: MfaTokenData;
  };
}

export interface LoginError {
  code: LoginErrorCode;
  message: string;
  mfa?: MfaToken;
}
